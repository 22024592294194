import { Controller } from "@hotwired/stimulus";
import videojs from "video.js";

export default class extends Controller {
  static values = {
    url: String,
    type: String,
    thumbhashUrl: String,
    aspectRatio: Number,
    mode: { type: String, default: "responsive" }, // 'responsive' or 'mobile'
  };

  declare readonly urlValue: string;
  declare readonly typeValue: string;
  declare readonly thumbhashUrlValue: string;
  declare readonly aspectRatioValue: number;
  declare readonly modeValue: "responsive" | "mobile";

  private player: videojs.Player | null = null;

  connect(): void {
    this.setDynamicStyle();
    this.createVideoElement();
  }

  private setDynamicStyle(): void {
    const style = document.createElement("style");
    const parentWidth = this.element.clientWidth;
    const height = (parentWidth * this.aspectRatioValue).toFixed(2);

    if (this.modeValue === "mobile") {
      style.textContent = `#${this.element.id} { height: ${height}px; }`;
    } else {
      // responsive
      style.textContent = `@media (max-width: 767px) { #${this.element.id} { height: ${height}px; } }`;
    }

    // 既存のスタイルタグを更新または新規作成
    const existingStyle = this.element.querySelector("style");
    if (existingStyle) {
      existingStyle.textContent = style.textContent;
    } else {
      this.element.appendChild(style);
    }
  }

  private createVideoElement(): void {
    const videoElement = document.createElement("video");
    videoElement.className = "video-js";
    videoElement.playsInline = true;
    videoElement.muted = true;

    const sourceElement = document.createElement("source");
    sourceElement.src = this.urlValue;
    sourceElement.type = this.typeValue;
    videoElement.appendChild(sourceElement);

    this.element.appendChild(videoElement);
    this.initializePlayer(videoElement);
  }

  private initializePlayer(videoElement: HTMLElement): void {
    this.player = videojs(videoElement, {
      muted: true,
      loop: true,
      controls: false,
      preload: "auto",
      playsinline: true,
    });

    this.player.on("error", () => {
      console.error("Video playback error occurred:", this.player.error());
    });

    this.player.play().catch((error) => {
      console.log("Video playback failed", error);
    });

    // サムハッシュ画像の設定
    const thumbhashElement = document.createElement("div");
    thumbhashElement.className = "thumbhash";
    thumbhashElement.style.backgroundImage = `url(${this.thumbhashUrlValue})`;
    // this.videoContainer.appendChild(thumbhashElement);

    this.player.ready(() => {
      this.player?.on("loadeddata", () => {
        this.showVideo();
      });
    });
  }

  private showVideo(): void {
    if (this.player) {
      const videoElement = this.player.el().querySelector("video");
      if (videoElement) {
        videoElement.style.opacity = "1";
      }
    }
  }

  disconnect(): void {
    if (this.player) {
      this.player.dispose();
    }
  }
}
